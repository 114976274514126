<!-- <div class="container-fluid">
    <div class="timeline-flow-wrapper">
        <div class="step edge-step" *ngIf="topEdge">
            <div class="pipe-wrapper">
                <span class="pipe"></span>
            </div>
            <div class="status-text"></div>
        </div>
        <div class="step" *ngFor="let step of data; let i=index" [ngClass]="{'edge-step': i === data.length-1 &amp;&amp; bottomEdge}">
            <div class="pipe-wrapper">
                <span class="pipe" [ngStyle]="getStyles(step, 'pipe-styles')"></span>
                <span class="status-icon" [ngClass]="getStyles(step, 'icon-class')" [ngStyle]="getStyles(step, 'icon-styles')"></span>
            </div>
            <div class="status-text" [ngStyle]="getStyles(step, 'text-styles')">
                <div class="title">{{step.step}}</div>
                <div class="sub-title" *ngIf="step.subtext">{{step.subtext}}</div>
            </div>
        </div>
    </div>
</div> -->

<div class="container fade-in"> <!---->

        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-xs-12 col-md-12 col-lg-10 mx-auto">
                
                <!-- Timeline -->
                <ul class="timeline">
                    <li class="timeline-item bg-white rounded ml-3 p-4 shadow shadow-drop-center">
                        <div class="timeline-arrow"></div>
                         <h2 class="h5 mb-0">August 2022 – Sept 2022 | Junior Web Developer • Worldcoo • Barcelona.</h2><!--<span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>21 March, 2019</span> -->
                        <p class="text-small mt-2 font-weight-light"> {{ 'recentW.jobdes' | translate:{'jobdes': title} }}</p>
                    </li>
                    <li class="timeline-item bg-white rounded ml-3 p-4 shadow shadow-drop-center">
                        <div class="timeline-arrow"></div>
                         <h2 class="h5 mb-0">March 2022 – Jun 2022 | Web Developer • Orange Spain • Madrid.</h2><!--<span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>21 March, 2019</span> -->
                        <p class="text-small mt-2 font-weight-light"> {{ 'recentO.jobdes' | translate:{'jobdes': title} }}</p>
                    </li>
                    <li class="timeline-item bg-white rounded ml-3 p-4 shadow shadow-drop-center">
                        <div class="timeline-arrow"></div>
                         <h2 class="h5 mb-0">2019 – 2021 | Freelance • Madrid.</h2><!--<span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>21 March, 2019</span> -->
                        <p class="text-small mt-2 font-weight-light"> {{ 'recentF.jobdes' | translate:{'jobdes': title} }}</p>
                    </li>
                    <li class="timeline-item bg-white rounded ml-3 p-4 shadow shadow-drop-center">
                        <div class="timeline-arrow"></div>
                         <h2 class="h5 mb-0">2020 – 2020 | Intern. • LetMePark. • Madrid.</h2><!--<span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>21 March, 2019</span> -->
                        <p class="text-small mt-2 font-weight-light"> {{ 'recent1.jobdes' | translate:{'jobdes': title} }}</p>
                    </li>
                    <li class="timeline-item bg-white rounded ml-3 p-4 shadow shadow-drop-center">
                        <div class="timeline-arrow"></div>
                        <h2 class="h5 mb-0">2016 – 2019 | Coordinator. • Atmosphere Living. S.L. • Madrid.</h2><!--<span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>5 April, 2019</span>-->
                        <p class="text-small mt-2 font-weight-light">{{ 'recent2.jobdes.text1' | translate:{'jobdes': title} }} </p><br><p class="text-small mt-2 font-weight-light">{{ 'recent2.jobdes.text2' | translate:{'jobdes': title} }} </p>
                    </li>
                    <li class="timeline-item bg-white rounded ml-3 p-4 shadow shadow-drop-center">
                        <div class="timeline-arrow"></div>
                        <h2 class="h5 mb-0">2015 – 2016 | Graphic Designer • Embabia S.L. • Madrid.</h2><!--<span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>18 August, 2019</span>-->
                        <p class="text-small mt-2 font-weight-light">{{ 'recent3.jobdes' | translate:{'jobdes': title} }}</p>
                    </li>
                    <li class="timeline-item bg-white rounded ml-3 p-4 shadow shadow-drop-center">
                        <div class="timeline-arrow"></div>
                        <h2 class="h5 mb-0">2014-2014 | Intern • Infocarto • Madrid.</h2><!--<span class="small text-gray"><i class="fa fa-clock-o mr-1"></i>10 October, 2019</span>-->
                        <p class="text-small mt-2 font-weight-light">{{ 'recent4.jobdes.text1' | translate:{'jobdes': title} }} </p><br><p class="text-small mt-2 font-weight-light">{{ 'recent4.jobdes.text2' | translate:{'jobdes': title} }} </p>
                    </li>
                </ul><!-- End -->

            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>

<script>
        $(window).scroll(function () {

/* Check the location of each desired element */
$('.fade-in').each(function (i) {

    var bottom_of_object = $(this).position().top + $(this).outerHeight();
    var bottom_of_window = $(window).scrollTop() + $(window).height();

    /* If the object is completely visible in the window, fade it it */
    if (bottom_of_window > bottom_of_object) {

        $(this).animate({
            'opacity': '1'
        }, 500);

    }

});

});
</script>