import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formacion',
  templateUrl: './formacion.component.html',
  styleUrls: ['./formacion.component.css']
})
export class FormacionComponent implements OnInit {

  title = 'portfolio';

  constructor() { }

  ngOnInit(): void {
  }

}
