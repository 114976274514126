<mat-nav-list>
    <a mat-list-item routerLink="/aboutme" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Inicio</span>
    </a>
    <a mat-list-item routerLink="/trabajos" (click)="onSidenavClose()">
        <mat-icon>style</mat-icon> <span class="nav-caption">Portafolio</span>
    </a>
    <a mat-list-item routerLink="/contacto" (click)="onSidenavClose()">
        <mat-icon>contacts</mat-icon><span class="nav-caption">Contacto</span>
    </a>
    <div>
        <mat-icon class="licon">language</mat-icon><select mat-list-item #langSelect (change)="translate.use(langSelect.value)" class="custom-select sel">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang"> {{ lang }}</option>
    </select></div>
</mat-nav-list>