   <!-- Google Tag Manager -->
   <script>
    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KK82JHK');
</script>
<!-- End Google Tag Manager -->

<div class="container-fluid congen">
    <div class="row">
        <div class="col-xs-12 col-md-12 col-lg-12">
            <h3 class="ptitulos">Api con datos de la covid-19 por país</h3>
            <mat-form-field>
                <mat-label>Buscador</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ingrese el país" #input>
            </mat-form-field>
            <div class="exportadores">
                <p>Formatos disponibles para descargar.</p>
                <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName: 'covid19 reporte'})" class="excel">Excel</button>
                <button mat-raised-button (click)="exporter.exportTable('csv', {fileName: 'covid19 reporte'})" class="csv">CSV</button>
                <button mat-raised-button (click)="exporter.exportTable('json', {fileName: 'covid19 reporte'})" class="json">JSON</button>
                <button mat-raised-button (click)="exporter.exportTable('txt', {fileName: 'covid19 reporte'})" class="txt">Text</button>
            </div>

            <table mat-table matTableExporter [dataSource]="dataSource" matSort #exporter="matTableExporter" class="mat-elevation-z8 mat-table">

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
            
                <!-- Position Column -->
                <ng-container matColumnDef="updated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.updated | date:'dd/MM/yyyy'}} </td>
                </ng-container>
            
                <!-- Name Column -->
                <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                <td mat-cell *matCellDef="let element"> {{element.country}} </td>
                </ng-container>
            
                <!-- Weight Column -->
                <ng-container matColumnDef="cases">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Total de cotagiados </th>
                <td mat-cell *matCellDef="let element"> {{element.cases}} </td>
                </ng-container>
            
                <!-- Symbol Column -->
                <ng-container matColumnDef="todayCases">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Casos de hoy </th>
                <td mat-cell *matCellDef="let element"> {{element.todayCases}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="deaths">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total de fallecidos </th>
                    <td mat-cell *matCellDef="let element"> {{element.deaths}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="todayDeaths">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fallecidos de hoy </th>
                    <td mat-cell *matCellDef="let element"> {{element.todayDeaths}} </td>
                </ng-container>

                <ng-container matColumnDef="recovered">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Recuperados </th>
                    <td mat-cell *matCellDef="let element"> {{element.recovered}} </td>
                </ng-container>

                <ng-container matColumnDef="todayRecovered">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Recuperados de hoy </th>
                    <td mat-cell *matCellDef="let element"> {{element.todayRecovered}} </td>
                </ng-container>


                <ng-container matColumnDef="critical">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> UCI </th>
                    <td mat-cell *matCellDef="let element"> {{element.critical}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

            <div class="card prodes">
                <div class="card-body">
                    <h2><b>Descripcion:</b></h2>
                    <ul>
                        <li>
                            <p class="pdescripcion">Tabla con datos actualizados de la covid-19 a nivel global, la infomación se obtiene mediante de una <a href="https://corona.lmao.ninja/"><b>API</b></a></p>
                        </li>
                        <li>  Tecnologias usadas: <br>
                            <svg width="75" height="75" class="ltec" viewBox="0 0 256 272" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M.1 45.522L125.908.697l129.196 44.028-20.919 166.45-108.277 59.966-106.583-59.169L.1 45.522z" fill="#E23237"/><path d="M255.104 44.725L125.908.697v270.444l108.277-59.866 20.919-166.55z" fill="#B52E31"/><path d="M126.107 32.274L47.714 206.693l29.285-.498 15.739-39.347h70.325l17.233 39.845 27.99.498-82.179-174.917zm.2 55.882l26.496 55.383h-49.806l23.31-55.383z" fill="#FFF"/></svg>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>