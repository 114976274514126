   <!-- Google Tag Manager -->
   <script>
    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KK82JHK');
</script>
<!-- End Google Tag Manager -->

<div class="container maincon">
    <div class="row">
        <div class="col-xl-10 offset-xl-1">
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <mat-card>
                    <div class="hero-text">
                        <!-- <h2>Ernesto Silva Languasco</h2> -->
                        <p>{{ 'info.contact' | translate:{'contact': title } }}</p>
                        <!-- <img src="assets/img/bocetogrootcir.png" alt="fotopersonal" class="imgside"> -->
                            
                    </div>
                    <div class="hero-info text-focus-in">
                        <h2>{{ 'info.detail' | translate:{'detail': title } }} </h2>
                        <ul class="listadocon">
                            <li><i class="fa fa-github fa-lg"></i><a href="https://github.com/elneto264">{{ 'info.detail1' | translate:{'detail1': title } }}  </a></li>
                            <li><i class="fa fa-linkedin fa-lg" aria-hidden="true"></i>
                                <a href="https://www.linkedin.com/in/esilvalan/">{{ 'info.detail2' | translate:{'detail2': title } }} </a>
                            </li>
                            <li><i class="fa fa-envelope fa-lg"></i> ernestoster@gmail.com</li>
                            <li><i class="fa fa-phone fa-lg"></i> +34 602 38 47 99</li>
                        </ul>
                    </div>
                </mat-card>
                </div>
            </div>
        </div>
    </div>



</div>

