<div class="row">
    <div class="col-xs-12 col-md-12 col-lg-12">

        <ul class="list-group">

            <li class="list-group-item text-center active lgtitle"><b>{{ 'edu.comptitle' | translate:{'comptitle': title } }} </b></li>
            <!-- <li class="list-group-item"><b>{{ 'edu.comp4' | translate:{'comp4': title} }}</b> <br> -->
                <li class="list-group-item"><b>{{ 'edu.comp5' | translate:{'comp5': title} }}</b> <br>
            <li class="list-group-item"><b>{{ 'edu.comp1' | translate:{'comp1': title} }}</b> <br>
                <!-- <mat-progress-bar mode="buffer" value=50 bufferValue=60></mat-progress-bar> -->
            </li>
            <li class="list-group-item"><b>{{ 'edu.comp3' | translate:{'comp3': title} }}</b> <br>
                <!-- <mat-progress-bar mode="buffer" value=50 bufferValue=60></mat-progress-bar> -->
            </li>
            <li class="list-group-item"><b>{{ 'edu.comp2' | translate:{'comp2': title } }}</b></li>
            <!-- <li class="list-group-item">• Certificado de Profesionalidad de Confección y Publicación de Páginas Webs. 560 h SEPE. Madrid. 2019</li> -->
        </ul>

        <br>
        <ul class="list-group">
            <li class="list-group-item text-center active lgtitle"><b>{{ 'edu.acatitle' | translate:{'acatitle': title} }}</b></li>
            <li class="list-group-item">
                <p><b>{{ 'edu.aca1.text1' | translate:{'aca1': title} }} </b></p>
                <p>{{ 'edu.aca1.text2' | translate:{'aca1': title} }}</p>
            </li>
            <li class="list-group-item">
                <p><b>{{ 'edu.aca2.text1' | translate:{'aca2': title} }}</b></p>
                <p>{{ 'edu.aca2.text2' | translate:{'aca2': title} }}</p>
            </li>
            <li class="list-group-item">
                <p><b>{{ 'edu.aca3.text1' | translate:{'aca2': title} }} </b></p>
                <p>{{ 'edu.aca3.text2' | translate:{'aca2': title} }}</p>
            </li>
        </ul>
        <br>
        <ul class="list-group">
            <li class="list-group-item text-center active lgtitle"><b>{{ 'lan.ltitle' | translate:{'ltitle': title} }} </b></li>
            <li class="list-group-item"> {{ 'lan.lan1' | translate:{'lan1': title} }} </li>
            <li class="list-group-item"> {{ 'lan.lan2' | translate:{'lan2': title} }}
                <!-- <mat-progress-bar mode="buffer" value=25 bufferValue=45></mat-progress-bar> -->
            </li>
            <li class="list-group-item"> {{ 'lan.lan3' | translate:{'lan3': title} }}</li>
        </ul>
        <br>

    </div>
</div>