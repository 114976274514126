<div class="container fon">
    <div class="row">
        <div class="col-xs-6 col-md-6 col-lg-6 textbox ">
            <div class="descptxt" style="max-width: 540px;">
                <div class="row no-gutters">
                    <div class="col-md-4">
                        <img src="assets/img/ftperfilweb.jpg" class="card-img yoimg" alt="imagen personal">
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h2 class="card-title">{{ 'intro.ctitle' | translate:{'ctitle': title} }} </h2>
                            <p class="card-text tcard">{{ 'intro.subctitle' | translate:{'subctitle': title} }}</p>
                            <p class="card-text text-muted always">Always Beta!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-6 introtext ">
            <h1>{{ 'hola.title' | translate:{'title': title} }}</h1>
            <p> {{ 'intro.description.text1' | translate:{'description': title} }} </p><br>
            <p> {{ 'intro.description.text2' | translate:{'description': title} }} </p>
        </div>
    </div>
    <hr>
    <div class="row filatime">
        <div class="col-xs-12 col-md-12 col-lg-12">
            <h3 class="ptitulos">{{ 'job.jobexp' | translate:{'jobexp': title} }}</h3>
            <section class="bloques time">
                <br>
                <app-timeline [data]="statusData" [statuses]="StatusValues" [topEdge]="true" [bottomEdge]="true" style="width: 400px0; float:right;">
                </app-timeline>
            </section>
            <hr>

            <section class="bloques ">
                <br>
                <h3 class="ptitulos">{{ 'edu.eduexp' | translate:{'eduexp': title} }} </h3>
                <app-formacion></app-formacion>
            </section>
            <hr>
            <section class="bloquetec">
                <h3 class="ptitulos">{{ 'tec.tecexp' | translate:{'tecexp': title} }} </h3>
                <app-tecnologias></app-tecnologias>
                
            </section>

        </div>
    </div>
</div>





<script>
    $(window).scroll(function() {

        /* Check the location of each desired element */
        $('.fade-in').each(function(i) {

            var bottom_of_object = $(this).position().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            /* If the object is completely visible in the window, fade it it */
            if (bottom_of_window > bottom_of_object) {

                $(this).animate({
                    'opacity': '1'
                }, 500);

            }

        });

    });
</script>