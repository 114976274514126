import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  
  title = 'portfolio';

  constructor() { }

  ngOnInit(): void {
  }

}
