<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
        <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <link href="https://fonts.googleapis.com/css2?family=Spartan:wght@200&display=swap" rel="stylesheet"> 
<app-cabezera (sidenavToggle)="sidenav.toggle()"></app-cabezera>
        <main>
            <div [@myAnimation]="o.isActivated ? o.activatedRoute : ''">
                <router-outlet #o="outlet"></router-outlet>
            </div>
        </main>
    </mat-sidenav-content>

</mat-sidenav-container>

