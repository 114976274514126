<!-- <ul class="nav justify-content-center cabeza">
    <li class="nav-item">
        <a class="btn btn-dark bts" routerLink="/aboutme" role="button">Inicio</a>
    </li>
    <li class="nav-item">
        <a class="btn btn-dark bts" routerLink="/trabajos" role="button">Portafolio</a>
    </li>
    <li class="nav-item">
        <a class="btn btn-dark bts" routerLink="/contacto" role="button">Contacto</a>
    </li>
    <li class="nav-item barstat">
        <ejs-tooltip id="estado" content="Progreso actual de la web" position="RightCenter">
            <mat-progress-spinner mode="determinate" value="40" diameter="40"></mat-progress-spinner>
        </ejs-tooltip>
        <p id="estado"> Progreso actual de la web</p> 
    </li>
</ul> -->

<mat-toolbar class="custom-toolbar">
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon class="custom-icon">menu</mat-icon>
        </button>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li class="nav-item">
                <select #langSelect (change)="translate.use(langSelect.value)" class="custom-select sel">
                    <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
                  </select>
                <!-- <select class="custom-select sel">
                    <option value="en" (click)="useLanguage('en')" selected>🇬🇧</option>
                    <option value="de" (click)="useLanguage('de')">🇩🇪</option>
                    <option value="es" (click)="useLanguage('es')" >🇪🇸</option>
                </select> -->
            </li>
            <li class="nav-item">
                <a class="btn  bts" routerLink="/aboutme" role="button"> {{ 'intro.home' | translate:{'home': title} }}</a>
            </li>
            <li class="nav-item">
                <a class="btn bts" routerLink="/trabajos" role="button">{{ 'intro.portfolio' | translate:{'portfolio': title} }}</a>
            </li>
            <li class="nav-item">
                <a class="btn bts" routerLink="/contacto" role="button">{{ 'intro.contact' | translate:{'contact': title} }}</a>
            </li>
        </ul>
    </div>
</mat-toolbar>